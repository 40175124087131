import React from 'react';
import ReactDOM from 'react-dom';
import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import * as font from './iconPack';
import App from './App';
import './styles/style.css';
import setupLocatorUI from '@locator/runtime';

if (import.meta.env.DEV) {
  setupLocatorUI();
}

library.add(font as IconPack);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
